import React, { useState } from 'react';
import { Box, AppBar, Toolbar, Button, Typography, Divider, IconButton, Menu, MenuItem } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Keep the Brightness icon
import { Link } from 'react-router-dom';

function Layout({ children, toggleDarkMode }) {
  const [anchorElHome, setAnchorElHome] = useState(null);
  const [anchorElAnalytics, setAnchorElAnalytics] = useState(null);
  const [anchorElPartners, setAnchorElPartners] = useState(null); // New state for Partners menu

  const handleMenuOpen = (event, menu) => {
    if (menu === 'home') {
      setAnchorElHome(event.currentTarget);
    } else if (menu === 'analytics') {
      setAnchorElAnalytics(event.currentTarget);
    } else if (menu === 'partners') { // Handle Partners menu
      setAnchorElPartners(event.currentTarget);
    }
  };

  const handleMenuClose = () => {
    setAnchorElHome(null);
    setAnchorElAnalytics(null);
    setAnchorElPartners(null); // Close Partners menu
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <AppBar position="static" sx={{ backgroundColor: '#1976d2' }}>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            Quantum Void Labs
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" onClick={toggleDarkMode}>
              <Brightness4Icon />
            </IconButton>
  
            <IconButton
              component="a"
              href="https://discord.gg/KaAChTJ9qH"
              target="_blank"
              rel="noopener noreferrer"
              color="inherit"
              sx={{ marginLeft: '10px' }} // Add some spacing
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="white" viewBox="0 0 24 24">
                <path d="M12 2C6.48 2 2 6.48 2 12c0 5.52 4.48 10 10 10 5.52 0 10-4.48 10-10S17.52 2 12 2zm3.36 8.68c.01 0 .01 0 0 0 0 0 0 0 0 0-1.46-1.02-2.5-1.04-3.08-1.04-.43 0-1.05.08-1.48.37.34-.49.83-.96 1.52-1.3-.83.24-1.49.59-2.05 1.04-.5.43-.92.94-1.23 1.55-.32.57-.56 1.23-.72 1.93.37-.01.78-.05 1.14-.14.31-.09.65-.23 1.02-.41 1.49-.6 2.52-1.36 3.42-2.29-.12.04-.25.07-.38.08-.24.02-.48.03-.73.03.03-.15.04-.31.04-.47 0-1.6-.62-2.79-1.3-3.66.58.17 1.08.36 1.54.56.09.05.18.11.26.16.12-.2.3-.38.48-.54 1.26-1 2.7-1.67 4.27-1.67 1.63 0 2.83.76 3.77 1.5-.27-.03-.52-.1-.78-.23-.47-.23-.89-.51-1.29-.84-.1-.09-.19-.2-.29-.3.15.09.31.16.48.23.44.15.88.36 1.27.6-.24-.14-.47-.26-.68-.36-.2-.1-.38-.19-.54-.28.26.11.54.19.8.3-.18-.12-.35-.22-.54-.3.36.09.72.17 1.06.26-.32-.15-.61-.33-.87-.52-.25-.19-.49-.39-.74-.62.45.32.85.69 1.23 1.04z"/>
              </svg>
            </IconButton>
  
            <Button
              variant="contained"
              sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' }, margin: '0 5px', borderRadius: '20px', textTransform: 'none' }}
              onClick={(event) => handleMenuOpen(event, 'home')}
            >
              Home
            </Button>
            <Menu anchorEl={anchorElHome} open={Boolean(anchorElHome)} onClose={handleMenuClose}>
              <MenuItem component={Link} to="/" onClick={handleMenuClose}>Dashboard</MenuItem>
              <MenuItem component={Link} to="/market-making" onClick={handleMenuClose}>Market Making</MenuItem>
            </Menu>
  
            <Button
              variant="contained"
              sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' }, margin: '0 5px', borderRadius: '20px', textTransform: 'none' }}
              onClick={(event) => handleMenuOpen(event, 'analytics')}
            >
              Analytics
            </Button>
            <Menu anchorEl={anchorElAnalytics} open={Boolean(anchorElAnalytics)} onClose={handleMenuClose}>
              <MenuItem component={Link} to="/analytics" onClick={handleMenuClose}>Overview</MenuItem>
              <MenuItem component={Link} to="/funding-rates" onClick={handleMenuClose}>Funding Rates</MenuItem>
              <MenuItem component={Link} to="/all-symbols" onClick={handleMenuClose}>All Symbols</MenuItem>
              <MenuItem component={Link} to="/real-time-chart" onClick={handleMenuClose}>Liquidation Map</MenuItem>
            </Menu>
  
            <Button
              variant="contained"
              sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' }, margin: '0 5px', borderRadius: '20px', textTransform: 'none' }}
              onClick={(event) => handleMenuOpen(event, 'partners')}
            >
              Partners
            </Button>
            <Menu anchorEl={anchorElPartners} open={Boolean(anchorElPartners)} onClose={handleMenuClose}>
              <MenuItem
                component="a"
                href="https://aftermath.finance"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleMenuClose}
              >
                aftermath.finance
              </MenuItem>
              <MenuItem
                component="a"
                href="https://hop.ag"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleMenuClose}
              >
                hop.ag
              </MenuItem>
            </Menu>
  
            <Button
              variant="contained"
              sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' }, margin: '0 5px', borderRadius: '20px', textTransform: 'none' }}
              component="a"
              href="https://app.quantumvoid.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              QVLSwap
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#000', color: '#fff', '&:hover': { backgroundColor: '#333' }, margin: '0 5px', borderRadius: '20px', textTransform: 'none' }}
              component={Link} to="/login"
            >
              Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3, backgroundColor: '#121212', color: '#fff' }}>
        {children}
      </Box>
      <Divider />
    </Box>
  );
};  

export default Layout;
